<template>
<section class="px-3">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6">
    <!-- <b-row class="mx-0">
      <b-col class="p-0">
        <b-nav pills justified class="p-1 bg-burem rounded">
          <b-nav-item to="/leaderboard" class="actives text-hitam shadow-sm rounded">Leaderboard</b-nav-item>
          <b-nav-item to="/redeem" class="text-burem">Redeem</b-nav-item>
        </b-nav>
      </b-col>
    </b-row> -->
</div>
  <div class="pt-0">
    <b-img fluid alt="Vue logo" :src="require('@/assets/pesta-reward.png')" class="w-100 px-2" style="border-radius: 20px">
    </b-img>
    <ListLeaderboard :listBoard="leaderboardList" />
  </div>
    <Skor />
  </section>
</template>

<script>
// @ is an alias to /src
import ListLeaderboard from '@/components/ListLeaderboard.vue'
import Header from '@/components/Header.vue'
import Skor from '@/components/Skor.vue'
import { mapState } from 'vuex'
export default {
  name: 'Leaderboard',
  components: {
    ListLeaderboard,
    Header,
    Skor,
  },
  data() {
    return {
      titleHeader: "Leaderboard",
      showToken: false,
      showBg: true,
      // totalCoin: this.$store.state.coin,
      // dataList: [
      //   {
      //     msisdn: "62895345xxxx",
      //     point: "10000"
      //   },
      //   {
      //     msisdn: "62895111xxxx",
      //     point: "9000"
      //   },
      //   {
      //     msisdn: "62896443xxxx",
      //     point: "8000"
      //   },
      //   {
      //     msisdn: "62895645xxxx",
      //     point: "7000"
      //   },
      //   {
      //     msisdn: "62896349xxxx",
      //     point: "6000"
      //   },
      //   {
      //     msisdn: "62896340xxxx",
      //     point: "5000"
      //   },
      //   {
      //     msisdn: "62896340xxxx",
      //     point: "4500"
      //   },
      //   {
      //     msisdn: "62895040xxxx",
      //     point: "3000"
      //   },
      //   {
      //     msisdn: "62895390xxxx",
      //     point: "2500"
      //   },
      //   {
      //     msisdn: "62896140xxxx",
      //     point: "1000"
      //   },
      // ]
    };
  },
  mounted() {
  // console.log(this.$store.state.reward);
  },
  computed: {
    ...mapState([
      'coin',
      'leaderboardList',
    ]),
  },
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.leaderboard {
  margin-top: 7.5rem;
}
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
